// Select dark or light theme by commenting out the one you do not want.
// IMPORTANT: Also adjust the DARK variable in the src/environment/theme file

$font-path: '~@simpl/fonts/dist/fonts/';
@import '~@simpl/fonts/src/styles/siemens-serif';
@import '~@simpl/fonts/src/styles/siemens-sans';

$image-path: '~@simpl/siemens-brand-bootstrap/dist/images/';

// Import of siemens-brand-bootstrap. the order is essential
@import '~@simpl/siemens-brand-bootstrap/src/styles/variables-dark';
//@import '~@simpl/siemens-brand-bootstrap/src/styles/variables-dark.product';
//@import '~@simpl/siemens-brand-bootstrap/src/styles/variables-light';
//@import '~@simpl/siemens-brand-bootstrap/src/styles/variables-light.product';
@import '~@simpl/siemens-brand-bootstrap/src/styles/functions';
@import '~@simpl/siemens-brand-bootstrap/src/styles/siemens-colors';
@import '~@simpl/siemens-brand-bootstrap/src/styles/variables';
//@import '~@simpl/siemens-brand-bootstrap/src/styles/variables.product';
@import '~@simpl/siemens-brand-bootstrap/src/styles/mixins';
@import '~@simpl/siemens-brand-bootstrap/src/styles/root';

// feature stylesheets
@import '~@simpl/siemens-brand-bootstrap/src/styles/reboot';
@import '~@simpl/siemens-brand-bootstrap/src/styles/images';
@import '~@simpl/siemens-brand-bootstrap/src/styles/containers';
@import '~@simpl/siemens-brand-bootstrap/src/styles/grid';
@import '~@simpl/siemens-brand-bootstrap/src/styles/tables';
@import '~@simpl/siemens-brand-bootstrap/src/styles/forms';
@import '~@simpl/siemens-brand-bootstrap/src/styles/buttons';
@import '~@simpl/siemens-brand-bootstrap/src/styles/transitions';
@import '~@simpl/siemens-brand-bootstrap/src/styles/dropdown';
@import '~@simpl/siemens-brand-bootstrap/src/styles/button-group';
@import '~@simpl/siemens-brand-bootstrap/src/styles/nav';
@import '~@simpl/siemens-brand-bootstrap/src/styles/card';
@import '~@simpl/siemens-brand-bootstrap/src/styles/breadcrumb';
@import '~@simpl/siemens-brand-bootstrap/src/styles/pagination';
@import '~@simpl/siemens-brand-bootstrap/src/styles/badge';
@import '~@simpl/siemens-brand-bootstrap/src/styles/alerts';
@import '~@simpl/siemens-brand-bootstrap/src/styles/progress';
@import '~@simpl/siemens-brand-bootstrap/src/styles/list-group';
@import '~@simpl/siemens-brand-bootstrap/src/styles/close';
@import '~@simpl/siemens-brand-bootstrap/src/styles/toasts';
@import '~@simpl/siemens-brand-bootstrap/src/styles/modals';
@import '~@simpl/siemens-brand-bootstrap/src/styles/tooltip';
@import '~@simpl/siemens-brand-bootstrap/src/styles/popover';
@import '~@simpl/siemens-brand-bootstrap/src/styles/carousel';
@import '~@simpl/siemens-brand-bootstrap/src/styles/spinners';
@import '~@simpl/siemens-brand-bootstrap/src/styles/utilities';
@import '~@simpl/siemens-brand-bootstrap/src/styles/sidebar';
@import '~@simpl/siemens-brand-bootstrap/src/styles/type';

// siemens-brand-bootstrap specific styles
@import '~@simpl/siemens-brand-bootstrap/src/styles/si-footer';
@import '~@simpl/siemens-brand-bootstrap/src/styles/si-header';
@import '~@simpl/siemens-brand-bootstrap/src/styles/si-loading';
@import '~@simpl/siemens-brand-bootstrap/src/styles/si-skeleton-text';
@import '~@simpl/siemens-brand-bootstrap/src/styles/si-chips';

// siemens-brand-ng specific styles
@import '~@simpl/siemens-brand-ng/theme';
@import '~@simpl/siemens-brand-ng/ngx-datepicker/ngx-datepicker-dark';
//@import '~@simpl/siemens-brand-ng/ngx-datepicker/ngx-datepicker-dark.product';
//@import '~@simpl/siemens-brand-ng/ngx-datepicker/ngx-datepicker-light';
//@import '~@simpl/siemens-brand-ng/ngx-datepicker/ngx-datepicker-light.product';

// setting up siemens-brand-icons
$font-path: '~@simpl/siemens-brand-icons/dist/fonts';
@import '~@simpl/siemens-brand-icons/dist/style/simpl-siemens-brand-icons';

main {
  padding-top: 68px;
  //padding-top: 0px;
}

@include media-breakpoint-up(md) {
  main {
    padding-top: 128px;
    //padding-top: 0px;
  }
  section:after {
    height: calc(100vh + 10px) !important;
  }
}

@include media-breakpoint-up(lg) {
  main {
    padding-top: 100px;
    //padding-top: 0px;
  }
  footer {
      position: fixed !important;
  }
  section:after {
    height: calc(100vh - 20px) !important;
  }
}

.bg-dark {
  //--bs-bg-opacity: 1;
  //background-color: transparent !important;
}

footer {
  z-index: 99;
  font-size: 1rem;
  background-color: var(--bs-dark);
  bottom: 0;
  position: relative;
}
